import "./Portfolio.css";
import { Header } from "./Header";
import { PortfolioElement } from "./PortfolioElement";

import { Overlay } from "./Overlay";
import { PortfolioModal } from "./PortfolioModal";
import { useLoaderData, useNavigate } from "react-router";
import { PORTFOLIO_CATEGORIES } from "../portfolioContent";

export function Portfolio() {
  const navigate = useNavigate();
  const loaderData = useLoaderData();

  function closeModal() {
    navigate("/portfolio");
  }

  function onElementClick(event, elementId) {
    event.stopPropagation();
    navigate(`/portfolio/${elementId}`);
  }

  function renderModal() {
    const articles = PORTFOLIO_CATEGORIES.flatMap(
      (categories) => categories.articles
    );
    const article = articles.find(
      (article) => article.id == loaderData.elementId
    );
    if (!article) return null;
    return (
      <Overlay close={closeModal}>
        <PortfolioModal title={article.name} onCrossClick={closeModal}>
          {article.detailModalContent}
        </PortfolioModal>
      </Overlay>
    );
  }

  function renderArticle(article) {
    return (
      <PortfolioElement
        key={article.id}
        name={article.name}
        sumary={article.summary}
        image={article.image}
        onClick={(event) => onElementClick(event, article.id)}
      />
    );
  }

  function renderCategory(category) {
    return (
      <div className="Portfolio-Section" key={category.name}>
        <div className="Portfolio-Section-Grid">
          <h2>{category.name}</h2>
          {category.articles.map(renderArticle)}
        </div>
      </div>
    );
  }

  return (
    <div className="Portfolio-Wrapper">
      <Header />
      <div className="Portfolio">
        {PORTFOLIO_CATEGORIES.map(renderCategory)}
      </div>
      {renderModal()}
    </div>
  );
}
