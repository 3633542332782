//
// Media
//

// Desert Slide
import desertSlideCardImage from "./img/Home/DeserSlide/CardDesertSlide.jpg";
import desertSlideEntryImage from "./img/Home/DeserSlide/EntryDesertSlide.jpg";
import desertSlideMainClip from "./img/Home/DeserSlide/MainDesertSlide.mp4";
import desertSlideToolsImage from "./img/Home/DeserSlide/Overview.PNG";

// Desert Slider - Slider
import desertSlideSliderSliding from "./img/Home/DeserSlide/Slider/SlidingMovement.mp4";
import desertSlideSliderMultiplayer from "./img/Home/DeserSlide/Slider/Multiplayer.mp4";
import desertSlideSliderRaceTracking from "./img/Home/DeserSlide/Slider/RaceTracking.mp4";
import desertSlideSliderProcedural from "./img/Home/DeserSlide/Slider/Procedural.mp4";
import desertSlideSliderPost from "./img/Home/DeserSlide/Slider/Post.mp4";

// Asteroids
import asteroidsCardImage from "./img/Home/Asteroids/CardAsteroids.jpg";
import asteroidsEntryImage from "./img/Home/Asteroids/EntryAsteroids.jpg";
import asteroidsMainClip from "./img/Home/Asteroids/MainAsteroids.mp4";

// Asteroids - Slider
import asteroidsSliderGameClassImage from "./img/Home/Asteroids/Slider/SliderGameClass.PNG";
import asteroidsSliderGameClassClip from "./img/Home/Asteroids/Slider/SliderGameClasses.mp4";
import asteroidsSliderGameObjImage from "./img/Home/Asteroids/Slider/SliderGameObjects2.png";
import asteroidsSliderShipClip from "./img/Home/Asteroids/Slider/SliderShip.mp4";
import asteroidsSliderAsteroidsClip from "./img/Home/Asteroids/Slider/SliderAsteroids.mp4";
import asteroidsSliderBombClip from "./img/Home/Asteroids/Slider/SliderBomb.mp4";

// PopTheLock
import popTheLockCardImage from "./img/Home/PopTheLock/CardPoptheLock.jpg";
import popTheLockEntryImage from "./img/Home/PopTheLock/EntryPoptheLock.png";
import popTheLockMainImage from "./img/Home/PopTheLock/MainPoptheLock.gif";

// PopTheLock - Slider
import popTheLockSliderLightClip from "./img/Home/PopTheLock/Slider/Light.mp4";
import popTheLockSliderDisplacementClip from "./img/Home/PopTheLock/Slider/Displacement.mp4";
import popTheLockSliderSurfaceClip from "./img/Home/PopTheLock/Slider/Surface.mp4";
import popTheLockSliderCameraImage from "./img/Home/PopTheLock/Slider/Camera.gif";

//
// Tech
//
import {
  ModalContentImplementations,
  ModalContentLinks,
  ModalContentSection,
} from "./components/Modal";
import { Carousel } from "./components/Carousel";

const DESERT_SLIDE = {
  id: "desert-slide",
  name: "Desert Slide",
  engine: "UE 5.2",
  language: "C++",
  focus: "Character Movement",
  focusColor: "#5BB263",
  cardImage: desertSlideCardImage,
  entryMedia: <img src={desertSlideEntryImage}></img>,
  cardText:
    "An Unreal multiplayer desert racing game. Beat your opponents by using the desert to your advantage.",
  gameLinkActive: false,
  gameLink: "",
  codeLinkActive: true,
  codeLink:
    "https://github.com/marcelbittrich/unrealDesertSlide/tree/master/Source/DesertSlide",
  modalContent: (
    <>
      <ModalContentSection
        title="Main Goal"
        media={
          <video
            loop
            autoPlay
            muted
            playsInline
            src={desertSlideMainClip}
            type="video/mp4"
          ></video>
        }
      >
        <p>
          The main goal of this project was to implement sliding movement into
          the unreal engine movement component and react on the slope of the
          surface the player is sliding on. The player can transition into a
          slide by walking downhill or crouching. While sliding the speed will
          increase downhill and decrease uphill. Both effects are increased
          while the player crouches.
        </p>
      </ModalContentSection>
      <ModalContentSection
        title="Tools"
        media={<img src={desertSlideToolsImage}></img>}
        reversed
      >
        <ul>
          <li>Unreal Engine 5.2</li>
          <li>JetBrains Rider</li>
          <li>Git / SourceTree / GitHub</li>
          <li>Notion</li>
        </ul>
      </ModalContentSection>
      <ModalContentImplementations title="Implemented Elements">
        <Carousel
          items={[
            {
              headline: "Sliding Movement",
              text: "A custom movement component with sliding capability",
              image: "",
              video: desertSlideSliderSliding,
            },
            {
              headline: "Multiplayer",
              text: "Due to steam online subsystem and multiplayer menu system to create and find servers.",
              image: "",
              video: desertSlideSliderMultiplayer,
            },
            {
              headline: "Race Tracking",
              text: "With start, finish, checkpoint objects with timers and events.",
              image: "",
              video: desertSlideSliderRaceTracking,
            },
            {
              headline: "Procedural Environment",
              text: "Enabled with the use of unreal engine procedural content generation and runtime virtual textures.",
              image: "",
              video: desertSlideSliderProcedural,
            },
            {
              headline: "Post Process Material ",
              text: "To create catoonish outlines and a unique look.",
              image: "",
              video: desertSlideSliderPost,
            },
          ]}
        />
      </ModalContentImplementations>
    </>
  ),
};

const ASTEROIDS = {
  id: "asteroids",
  name: "Asteroids",
  engine: "Custom",
  language: "C++",
  focus: "Game Logic",
  focusColor: "#AB5BB2",
  cardImage: asteroidsCardImage,
  entryMedia: <img src={asteroidsEntryImage}></img>,
  cardText:
    "An asteroids like minigame where you fly around and shoot asteroids to get the high-score. Use bombs to get out of dangerous situations!",
  gameLinkActive: true,
  gameLink: "https://marcelbittrich.itch.io/asteroid-game-project",
  codeLinkActive: true,
  codeLink: "https://github.com/marcelbittrich/asteroidsGame",
  modalContent: (
    <>
      <ModalContentSection
        title="Main Goal"
        media={
          <video
            loop
            autoPlay
            muted
            playsInline
            src={asteroidsMainClip}
            type="video/mp4"
          ></video>
        }
      >
        <p>
          The main goal with this project was to learn and write C++. The whole
          game loop and logic is written without an additional game engine. To
          render elements to the screen the SDL2 library was used. A challenging
          and fun game was achieved by implementing physically correct
          collisions and movement as well as a nice visual representation with
          the help of an interactive background.
        </p>
      </ModalContentSection>
      <ModalContentImplementations title="Implemented Elements">
        <Carousel
          items={[
            {
              headline: "Game class",
              text: "with initialization, event handler, update and render functions",
              image: asteroidsSliderGameClassImage,
              video: "",
            },
            {
              headline: "Game objects",
              text: "Objects that can collide and interact with each other. All textures were made in Illustrator.",
              image: asteroidsSliderGameObjImage,
              video: "",
            },
            {
              headline: "Ship",
              text: "that can be controlled by the player, can shoot, has a shot-meter to limit constant shooting and has a thrust animation.",
              image: "",
              video: asteroidsSliderShipClip,
            },
            {
              headline: "Asteroids",
              text: "Objects that can spawn in two different size variation, collide physically accurate based on their size and velocity and can be destroyed or split in two.",
              image: "",
              video: asteroidsSliderAsteroidsClip,
            },
            {
              headline: "Bombs",
              text: "Objects that can be collected after destroing enough asteroids and clear a large area.",
              image: "",
              video: asteroidsSliderBombClip,
            },
            {
              headline: "Background",
              text: "A single objet that visualizes movement with many colorful points.",
              image: "",
              video: asteroidsMainClip,
            },
          ]}
        />
      </ModalContentImplementations>
    </>
  ),
};

const POP_THE_LOCK = {
  id: "pop-the-lock",
  name: "Pop the Lock",
  engine: "Unity",
  language: "C#",
  focus: "Arcade",
  focusColor: "#924D4D",
  cardImage: popTheLockCardImage,
  entryMedia: <img src={popTheLockEntryImage}></img>,
  cardText:
    "Train your reflexes with this microgame. Try and unlock new highscores by hitting just in the right moment.",
  gameLinkActive: true,
  gameLink: "https://play.unity.com/mg/other/build-7rb",
  codeLinkActive: false,
  codeLink: "",
  modalContent: (
    <>
      <ModalContentSection
        title="Main Goal"
        media={<img src={popTheLockMainImage}></img>}
      >
        <p>
          The main goal with this project was to recreate a game in unity and to
          get familiar with the engine. It was also used to try out the camera
          shake functionality within unity.
        </p>
      </ModalContentSection>
      <ModalContentImplementations title="Other cool stuff I do in Unity">
        <Carousel
          items={[
            {
              headline: "Light Shaders",
              text: "For stylized looks such as different cartoon shaders.",
              image: "",
              video: popTheLockSliderLightClip,
            },
            {
              headline: "Displacement Shaders",
              text: "Shaders to recreate wave patterns with trochoidal wave function.",
              image: "",
              video: popTheLockSliderDisplacementClip,
            },
            {
              headline: "Object Shaders",
              text: "Saders in Unity’s Universal Render Pipeline (URP) for objects such as glowing, translucency or dissolving effects.",
              image: "",
              video: popTheLockSliderSurfaceClip,
            },
            {
              headline: "3rd Person Camera ",
              text: "A system made with Unity’s Cinemachine, with the option to zoom in and focus while changing the movement from free rotation to strafe.",
              image: popTheLockSliderCameraImage,
              video: "",
            },
          ]}
        />
      </ModalContentImplementations>
    </>
  ),
};

export const GAME_CARDS = [DESERT_SLIDE, ASTEROIDS, POP_THE_LOCK];
