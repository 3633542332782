import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./Carousel.css";
import { useState, cloneElement, useRef } from "react";
import { ReactComponent as LeftRightArrow } from "../img/backArrow.svg";
import { useCallback } from "react";

// Slide the "old" image to the left.
const SLIDE_DIRECTION_LEFT = "left";
// Slide the "old" image to the right.
const SLIDE_DIRECTION_RIGHT = "right";

function SlideAnimation(props) {
  const { direction, itemKey } = props;
  return (
    <TransitionGroup
      childFactory={(child) =>
        cloneElement(child, { classNames: `slide-${direction}` })
      }
      component={null}
    >
      <CSSTransition
        key={itemKey}
        timeout={5000}
        classNames={`slide-${direction}`}
      >
        {props.children}
      </CSSTransition>
    </TransitionGroup>
  );
}

const SWIPE_THRESHOLD = 40

export function Carousel(props) {
  const [selectedItemIdx, setSelectedItemIdx] = useState(0);
  const items = props.items;
  const itemCount = items.length;
  const selectedItem = items[selectedItemIdx];
  const itemIndices = [...Array(itemCount).keys()];
  const [slideDirection, setSlideDirection] = useState(SLIDE_DIRECTION_RIGHT);
  const touchStart = useRef(null);
  const touchEnd = useRef(null);

  const selectAndSlide = useCallback(newItemIdx => {
    // Do nothing if out of range.
    if (newItemIdx < 0 || newItemIdx >= itemCount) {
      return
    }
    // If going to an image after, slide the old image to the left.
    // If going to an image before, slide the old image to the right.
    const slideDirection = newItemIdx > selectedItemIdx ? SLIDE_DIRECTION_LEFT : SLIDE_DIRECTION_RIGHT
    setSlideDirection(slideDirection);
    setSelectedItemIdx(newItemIdx);
  }, [setSlideDirection, itemCount, selectedItemIdx])


  function handleTouchStart(event) {
    const startX = event.targetTouches[0].clientX
    touchStart.current = startX
  }

  function handleTouchMove(event) {
    const currentX = event.targetTouches[0].clientX
    touchEnd.current = currentX
  }

  function handleTouchEnd() {
    const swipeDistance = touchEnd.current - touchStart.current
    const goLeft = swipeDistance > SWIPE_THRESHOLD
    const goRight = swipeDistance < -SWIPE_THRESHOLD
    if (goLeft) {
      selectAndSlide(selectedItemIdx - 1)
    } else if (goRight) {
      selectAndSlide(selectedItemIdx + 1)
    }
  }


  function renderSelectionArea() {
    return (
      <div className="Carousel-Selection-Area">
        {itemIndices.map(renderSelectionBubble)}
      </div>
    );
  }

  function renderSelectionBubble(itemIdx) {
    const className =
      itemIdx === selectedItemIdx
        ? "Carousel-Selection-Bubble-Active Carousel-Selection-Bubble"
        : "Carousel-Selection-Bubble";
    return (
      <div
        key={itemIdx}
        className={className}
        onClick={() => selectAndSlide(itemIdx)}
      />
    );
  }

  return (
    <div>
      <div>
        <div className="Carousel-Media-Wrapper">
          <SlideAnimation direction={slideDirection} itemKey={selectedItemIdx}>
            <video
              loop
              autoPlay
              muted
              playsInline
              poster={selectedItem.image}
              key={selectedItemIdx}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <source src={selectedItem.video} type="video/mp4" />
            </video>
          </SlideAnimation>
          <div
            className="Carousel-Arrow-Left"
            onClick={() => selectAndSlide(selectedItemIdx - 1)}
          >
            <LeftRightArrow />
          </div>
          <div
            className="Carousel-Arrow-Right"
            onClick={() => selectAndSlide(selectedItemIdx + 1)}
          >
            <LeftRightArrow />
          </div>
        </div>
      </div>
      {renderSelectionArea()}
      <div className="Carousel-Text-Wrapper-Wrapper">
        <SlideAnimation direction={slideDirection} itemKey={selectedItemIdx}>
          <div
            className="Carousel-Text-Wrapper"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <h3>{selectedItem.headline}</h3>
            <p>{selectedItem.text}</p>
          </div>
        </SlideAnimation>
      </div>
    </div>
  );
}

function clamp(value, min, max) {
  return Math.max(min, Math.min(value, max));
}
