import portraitImage from "../img/Portrait.jpg";
import { Header } from "./Header";
import "./About.css";

export function About() {
  return (
    <div className="About-Wrapper">
      <Header />
      <div className="About">
        <div className="About-Media-Wrapper">
          <img src={portraitImage}></img>
        </div>
        <div className="About-Text-Wrapper">
          <h1>Marcel Bittrich</h1>
          <h3>App | Web | Game Developer</h3>
          <p>
            I am a automotive engineer graduate, who has worked in aviation as a
            structural engineer, with a strong passion for programming. I like
            to build websites, applications, and games using various languages
            and tools. I am continuously educating myself independently to
            enhance my skills.
          </p>
          <p>
            This website aims to show my progress and achievements so far. Feel
            free to take a look inside my portfolio or play my games!
          </p>
        </div>
      </div>
    </div>
  );
}

//
