import { PortfolioSection } from "./components/PortfolioContent";

// Images

import asteroidsImage from "./img/Asteroids.PNG";
import popTheLockImage from "./img/PoptheLock.webp";

//
// GameDev
//

// C++
import gameLanguageImage from "./img/GameDevLanguage.png";
import gamedevCppImage01 from "./img/Portfolio/GameDev/C++/GameCode.PNG";
import gamedevCppImage02 from "./img/Portfolio/GameDev/C++/Code.PNG";

// Gameplay
import desertSlideImage from "./img/DesertSlide.jpg";
import gamedevGameplayImage01 from "./img/Portfolio/GameDev/Gameplay/DesertSlide.gif";

// Multiplayer
import shooterImage from "./img/Shooter.webp";
import gamedevMultiplayerImage01 from "./img/Portfolio/GameDev/Multiplayer/Host_MyServerName.PNG";
import gamedevMultiplayerImage02 from "./img/Portfolio/GameDev/Multiplayer/Shooter.png";

// Environment
import gamedevEnviroImage01 from "./img/Portfolio/GameDev/Environment/RoboToonShaderComp.jpg";
import gamedevEnviroImage02 from "./img/Portfolio/GameDev/Environment/UnrealPCG.PNG";

// Logic
import gamedevLogicImage01 from "./img/Portfolio/GameDev/Logic/explosion.PNG";

// Design
import gamedevDesignImage01 from "./img/Portfolio/GameDev/Design/AsteroidAssets.PNG";

// Planning
import gamedevPlanningMainImage from "./img/Portfolio/GameDev/Planning/Planning.PNG";
import gamedevPlanningClip01 from "./img/Portfolio/GameDev/Planning/Planning.mp4";

//
// Unreal Engine
//

// Environment
import unrealEnviroMainImage from "./img/Portfolio/UnrealEngine/Environment/PCG.jpg";
import unrealEnviroClip01 from "./img/Portfolio/UnrealEngine/Environment/PCG.mp4";

//
// Unity
//

// Shader
import unityShaderMainImage from "./img/Portfolio/Unity/Shader/RoboToonShaderComp.jpg";
import unityShaderClip01 from "./img/Portfolio/Unity/Shader/Light.mp4";
import unityShaderClip02 from "./img/Portfolio/Unity/Shader/Surface.mp4";
import unityShaderClip03 from "./img/Portfolio/Unity/Shader/Displacement.mp4";

// Gameplay
import unityGameplayMainImage from "./img/Portfolio/Unity/Gameplay/AimCam.jpg";
import unityGameplayImage01 from "./img/Portfolio/Unity/Gameplay/Camera.gif";

// Prototyping
import unityPrototypingMainImage from "./img/Portfolio/Unity/Prototyping/PrototypeIslandGrey.jpg";

//
// WebDev
//

// React
import webDevReactMainImage from "./img/Portfolio/WebDev/React/WebDevLanguage.png";
import webDevReactImage01 from "./img/Portfolio/WebDev/React/Figma.PNG";

// Website
import webDevWebsiteMainImage from "./img/Portfolio/WebDev/Website/Website.PNG";

const GAMEDEV_LANGUAGE_ARTICLE = {
  id: "gamedev-language",
  name: "C++ / C#",
  summary:
    "I am experienced with pure C++ as well as the implementation of C++ and C# functions within Unreal Engine and Unity.",
  image: gameLanguageImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={gamedevCppImage01}></img>}
        title="Pure C++"
      >
        <p>
          I programmed a game without any game engine by using C++ and the sdl2
          library. It taught me the importance of memory management, debugging
          with visual studio, performance profiling and writing clean and
          readable code.
        </p>
      </PortfolioSection>
      <PortfolioSection
        media={<img src={gamedevCppImage02}></img>}
        title="C++ in Unreal Engine / C# in Unity"
      >
        <p>
          I've developed classes for both Unity and Unreal Engine, and I'm
          skilled in integrating scripts in both environments. My expertise in
          Unreal Engine includes a deep understanding of its internal functions,
          engine structure, and proper class allocation for various aspects of
          game logic.
        </p>
      </PortfolioSection>
    </>
  ),
};

const GAMEDEV_GAMEPLAY_ARTICLE = {
  id: "gamedev-gameplay",
  name: "Gameplay",
  summary: "",
  image: desertSlideImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={gamedevGameplayImage01}></img>}
        title="Character Movement in Unreal Engine"
      >
        <p>
          I developed a movement component from concept to implementation,
          including surface detection and slope-based acceleration. This
          component replicates and is multiplayer-capable as it builds upon
          Unreal Engine's built-in movement component.
        </p>
      </PortfolioSection>
    </>
  ),
};

const GAMEDEV_MULTIPLAYER_ARTICLE = {
  id: "gamedev-multiplayer",
  name: "Multiplayer",
  summary: "",
  image: shooterImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={gamedevMultiplayerImage01}></img>}
        title="Menu System and Online Subsystem"
      >
        <p>
          I wrote code to enable player connection through the Steam online
          subsystem in Unreal Engine. Additionally, I implemented a menu system
          that allows players to independently create servers or join existing
          ones.
        </p>
      </PortfolioSection>
      <PortfolioSection
        media={<img src={gamedevMultiplayerImage02}></img>}
        title="Shooter"
      >
        <p>
          I developed multiplayer shooter network code that facilitates
          Server-Client replication and utilizes Remote Procedure Calls (RPCs)
          from clients. This allows players to engage in multiplayer gameplay,
          including shooting, jumping, strafing, reloading, and object throwing,
          all synchronized and replicated to all clients.
        </p>
      </PortfolioSection>
    </>
  ),
};

const GAMEDEV_ENVIRONMENT_ARTICLE = {
  id: "gamedev-environment",
  name: "Environment",
  summary: "",
  image: gamedevEnviroImage02,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={gamedevEnviroImage01}></img>}
        title="Custom Shaders in Unity"
      >
        <p>
          I utilized Unity to create shaders, customizing game visuals and
          enabling complex movements like wave patterns.
        </p>
      </PortfolioSection>
      <PortfolioSection
        media={<img src={gamedevEnviroImage02}></img>}
        title="Precudral Landcapes in Unreal"
      >
        <p>
          I designed procedural landscapes for quick map transformations and
          efficiently placed assets using Unreal's procedural content generation
          tool to adapt to the landscape, streamlining the creation of vast game
          environments.
        </p>
      </PortfolioSection>
    </>
  ),
};

const GAMEDEV_LOGIC_ARTICLE = {
  id: "gamedev-logic",
  name: "Logic",
  summary: "",
  image: gamedevLogicImage01,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={gamedevLogicImage01}></img>}
        title="Pure C++ Game Logic"
      >
        <p>
          I implemented a custom game loop in C++ that incorporated event
          handling, update, and render functions to ensure a seamless and
          responsive gaming experience.
        </p>
        <p>
          I developed a robust collision detection system that provided
          physically accurate 2D interactions, enhancing the realism and
          immersion of the games I worked on.
        </p>
        <p>
          I organized code into distinct classes, each equipped with its own
          unique implementation of update and render functions, facilitating
          efficient and modular game development.
        </p>
      </PortfolioSection>
    </>
  ),
};

const GAMEDEV_DESIGN_ARTICLE = {
  id: "gamedev-design",
  name: "Design",
  summary: "",
  image: gamedevDesignImage01,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={gamedevDesignImage01}></img>}
        title="Game Assets"
      >
        <p>
          I designed game assets using Adobe Illustrator to achieve a cohesive
          and minimalist visual style that perfectly complemented the arcade
          nature of the games I developed.
        </p>
      </PortfolioSection>
    </>
  ),
};

const GAMEDEV_PLANNING_ARTICLE = {
  id: "gamedev-planning",
  name: "Planning",
  summary: "",
  image: gamedevPlanningMainImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={
          <video
            loop
            autoPlay
            muted
            playsInline
            src={gamedevPlanningClip01}
            type="video/mp4"
          ></video>
        }
        title="Project Planning and Organization"
      >
        <p>
          I use Notion to plan and organize my projects, helping me manage my
          time and resources effectively. I rely on Git and GitHub to save and
          structure my code, ensuring version control and collaboration.
          Additionally, I simplify my Git workflow with SourceTree GUI.
        </p>
      </PortfolioSection>
    </>
  ),
};

const UE_MULTIPLAYER_ARTICLE = {
  id: "ue-planning",
  name: "Multiplayer",
  summary: "",
  image: shooterImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={gamedevMultiplayerImage01}></img>}
        title="Menu System and Online Subsystem"
      >
        <p>
          I wrote code to enable player connection through the Steam online
          subsystem in Unreal Engine. Additionally, I implemented a menu system
          that allows players to independently create servers or join existing
          ones.
        </p>
      </PortfolioSection>
      <PortfolioSection
        media={<img src={gamedevMultiplayerImage02}></img>}
        title="Shooter"
      >
        <p>
          I developed multiplayer shooter network code that facilitates
          Server-Client replication and utilizes Remote Procedure Calls (RPCs)
          from clients. This allows players to engage in multiplayer gameplay,
          including shooting, jumping, strafing, reloading, and object throwing,
          all synchronized and replicated to all clients.
        </p>
      </PortfolioSection>
    </>
  ),
};

const UE_MOVEMENT_ARTICLE = {
  id: "ue-movement",
  name: "Character Movement",
  summary: "",
  image: desertSlideImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={gamedevGameplayImage01}></img>}
        title="Character Movement in Unreal Engine"
      >
        <p>
          I developed a movement component from concept to implementation,
          including surface detection and slope-based acceleration. This
          component replicates and is multiplayer-capable as it builds upon
          Unreal Engine's built-in movement component.
        </p>
      </PortfolioSection>
    </>
  ),
};

const UE_ENVIRONMENT_ARTICLE = {
  id: "ue-environment",
  name: "Environment",
  summary: "",
  image: unrealEnviroMainImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={
          <video
            loop
            autoPlay
            muted
            playsInline
            src={unrealEnviroClip01}
            type="video/mp4"
          ></video>
        }
        title="Landscapes"
      >
        <p>
          I leveraged the Landmass and Water plugin to create procedural
          landscapes, enabling rapid transformations of map aesthetics and
          layout. Assets were seamlessly integrated using Unreal Engine's
          procedural content generation tool, adapting harmoniously to the
          landscape, making the creation of vast game worlds a streamlined
          process.
        </p>
      </PortfolioSection>
      <PortfolioSection media={<span></span>} title="Runtime Virtual Textures">
        <p>
          I used Runtime Virtual Textures (RVT) to optimize performance by
          dynamically streaming landscape textures. To enhance the visual
          appeal, I projected textures onto the landscape using RVT, adding
          depth and realism to in-game terrains.
        </p>
      </PortfolioSection>
      <PortfolioSection media={<span></span>} title="Materials">
        <p>
          My expertise extends to crafting materials for landscapes that react
          realistically to slope and height variations, seamlessly integrating
          them into the procedural nature of the environment.
        </p>
        <p>
          I also fine-tuned post-process materials to individualize the game's
          visual style, contributing to the unique and immersive experience
          players encounter in my game projects.
        </p>
      </PortfolioSection>
    </>
  ),
};

const UE_CLASSES_ARTICLE = {
  id: "ue-classes",
  name: "C++ & Blueprints",
  summary: "",
  image: gameLanguageImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<span></span>}
        title="Classes in C++ and Blueprints"
      >
        <p>
          I possess the proficiency to work with both Blueprints and C++
          classes, offering versatility in game development. My expertise
          extends to seamlessly displaying variables and essential information
          from within C++ classes to the Blueprint classes. This facilitates
          easy editing by designers or myself, streamlining the game design
          process.
        </p>
      </PortfolioSection>
    </>
  ),
};

const UNITY_SHADER_ARTICLE = {
  id: "unity-shader",
  name: "Shader",
  summary: "",
  image: unityShaderMainImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={
          <video
            loop
            autoPlay
            muted
            playsInline
            src={unityShaderClip01}
            type="video/mp4"
          ></video>
        }
        title="Light Shader"
      >
        <p>
          I have developed light shaders that enable the creation of
          customizable toon shaders, allowing for diverse and visually appealing
          styles in game graphics.
        </p>
      </PortfolioSection>
      <PortfolioSection
        media={
          <video
            loop
            autoPlay
            muted
            playsInline
            src={unityShaderClip02}
            type="video/mp4"
          ></video>
        }
        title="Surface Shader"
      >
        <p>
          My skill set includes the creation of surface shaders capable of
          producing a range of effects, including holograms, dissolves, and
          shape warping. This expertise enhances the visual dynamics and
          immersive qualities of the games I work on.
        </p>
      </PortfolioSection>
      <PortfolioSection
        media={
          <video
            loop
            autoPlay
            muted
            playsInline
            src={unityShaderClip03}
            type="video/mp4"
          ></video>
        }
        title="Displacement Shader"
      >
        <p>
          Additionally, I've crafted displacement shaders that can generate
          realistic wave patterns from a basic, highly tessellated plane, adding
          a dynamic and natural touch to the in-game environments.
        </p>
      </PortfolioSection>
    </>
  ),
};

const UNITY_GAMEPLAY_ARTICLE = {
  id: "unity-gameplay",
  name: "Gameplay",
  summary: "",
  image: unityGameplayMainImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={unityGameplayImage01}></img>}
        title="Character Animation, Movement and Camera"
      >
        <p>
          I have expertise in Unity's movement system, including animation
          creation for actions like walking, running, strafing, and aiming,
          along with smooth transition rules. I've also utilized Unity's
          Cinemachine to develop third-person and aim cameras, enhancing
          gameplay immersion.
        </p>
      </PortfolioSection>
    </>
  ),
};

const UNITY_PROTOTYPING_ARTICLE = {
  id: "unity-prototyping",
  name: "Prototyping",
  summary: "",
  image: unityPrototypingMainImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={unityPrototypingMainImage}></img>}
        title="Blockouts with ProBuilder"
      >
        <p>
          I employed ProBuilder to construct prototype scenes, facilitating the
          evaluation of various lighting scenarios and rapid visualization of
          ideas during game development.
        </p>
      </PortfolioSection>
    </>
  ),
};

const WEBDEV_LANGUAGE_ARTICLE = {
  id: "webdev-language",
  name: "React/ JS/ HTML/ CSS",
  summary: "",
  image: webDevReactMainImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={webDevReactImage01}></img>}
        title="Frontend Development"
      >
        <p>
          I am proficient in using React, JavaScript, HTML, and CSS, enabling me
          to bring designs from Figma to life by reproducing them into
          functional and visually appealing websites.
        </p>
      </PortfolioSection>
    </>
  ),
};

const WEBDEV_WEBSITE_ARTICLE = {
  id: "webdev-mywebsite",
  name: "My Website",
  summary: "",
  image: webDevWebsiteMainImage,
  detailModalContent: (
    <>
      <PortfolioSection
        media={<img src={webDevWebsiteMainImage}></img>}
        title="marcelbittrich.com"
      >
        <p>
          My website is a testament to my skills and dedication. I personally
          designed and developed this site to showcase my expertise and
          commitment in the field. It serves as a reflection of my capabilities
          and a platform to share my knowledge with others.
        </p>
      </PortfolioSection>
    </>
  ),
};

export const PORTFOLIO_CATEGORIES = [
  {
    name: "Game Development",
    articles: [
      GAMEDEV_LANGUAGE_ARTICLE,
      GAMEDEV_GAMEPLAY_ARTICLE,
      GAMEDEV_MULTIPLAYER_ARTICLE,
      GAMEDEV_ENVIRONMENT_ARTICLE,
      GAMEDEV_LOGIC_ARTICLE,
      GAMEDEV_DESIGN_ARTICLE,
      GAMEDEV_PLANNING_ARTICLE,
    ],
  },
  {
    name: "Unreal Engine",
    articles: [
      UE_MULTIPLAYER_ARTICLE,
      UE_MOVEMENT_ARTICLE,
      UE_ENVIRONMENT_ARTICLE,
      UE_CLASSES_ARTICLE,
    ],
  },
  {
    name: "Unity",
    articles: [
      UNITY_SHADER_ARTICLE,
      UNITY_GAMEPLAY_ARTICLE,
      UNITY_PROTOTYPING_ARTICLE,
    ],
  },
  {
    name: "Web Development",
    articles: [WEBDEV_LANGUAGE_ARTICLE, WEBDEV_WEBSITE_ARTICLE],
  },
];
