import "./PortfolioElement.css";

export function PortfolioElement(props) {
  return (
    <div className="PortfolioElement" onClick={props.onClick}>
      <div className="PortfolioElement-Media-Wrapper">
        <img src={props.image}></img>
      </div>
      <div className="PortfolioElement-Preview-Wrapper">
        <img src={props.image}></img>
      </div>
      <h3>{props.name}</h3>
    </div>
  );
}
