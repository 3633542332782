import './PortfolioContent.css'

export function PortfolioSection(props) {
    return (
        <div className="PortfolioModal-Content-Section">
          <div className="PortfolioModal-Media-Wrapper">{props.media}</div>
          <div className="PortfolioModal-Text-Wrapper">
            <h3>{props.title}</h3>
            {props.children}
          </div>
        </div>
    )
}