import "./GameCard.css";

export function GameCard(props) {
  const className = props.className
    ? `GameCard ${props.className}`
    : "GameCard";

  return (
    <div
      onClick={props.onClick}
      className={className}
      style={{
        transform: `translate(${props.xOffset}, ${props.yOffset}) rotate(${props.rotation})`,
      }}
    >
      {props.children}
      <div className="GameCard-Media-Wrapper">
        <img src={props.image}></img>
      </div>
      <div className="GameCard-Text-Wrapper">
        <div className="GameCard-Title">
          <h3>{props.name}</h3>
        </div>
        <div className="GameCard-Tech-Item-Container">
          <div className="GameCard-Tech-Item EngineTag">{props.engine}</div>
          <div className="GameCard-Tech-Item LanguageTag">{props.language}</div>
          <div
            className="GameCard-Tech-Item FocusTag"
            style={{ backgroundColor: `${props.focusColor}` }}
          >
            {props.focus}
          </div>
        </div>
        <div className="GameCard-Text">
          <p>{props.text}</p>
        </div>
      </div>
    </div>
  );
}
