import "./PortfolioModal.css";
import { ReactComponent as Cross } from "../img/cross.svg";

export function PortfolioModal(props) {
  return (
    <div
      className="PortfolioModal-Wrapper"
      onClick={(event) => event.stopPropagation()}
    >
      <div className="PortfolioModal-Headline-Wrapper">
        <h2>{props.title}</h2>
      </div>
      <div className="PortfolioModal-Content-Wrapper">{props.children}</div>
      <div className="PortfolioModal-Cross-Background"></div>
      <div
        className="PortfolioModal-Cross-Wrapper"
        onClick={props.onCrossClick}
      >
        <Cross />
      </div>
    </div>
  );
}
