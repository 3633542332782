import { Header } from "./Header";
import "./Home.css";
import { GameCard } from "./GameCard";
import { useState, useEffect, useRef } from "react";
import { ReactComponent as Arrow } from "../img/arrow.svg";
import { ReactComponent as Click } from "../img/click.svg";
import { ReactComponent as Lines } from "../img/lines.svg";
import { ReactComponent as BackCross } from "../img/cross.svg";
import { ReactComponent as LeftRightArrow } from "../img/backArrow.svg";

import { Overlay } from "./Overlay";
import { Modal } from "./Modal";
import { useLoaderData, useNavigate } from "react-router";
import { GAME_CARDS } from "../homeContent";

const coords = {
  x: 0,
  y: 0,
};

export function Home() {
  const [isDetailView, setIsDetailView] = useState(false);
  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const [mobileSelected, setMobileSelected] = useState(0);

  const stateClassName = isDetailView
    ? "State-Wrapper State-Wrapper-DetailView"
    : "State-Wrapper State-Wrapper-Overview";

  function closeModal() {
    navigate("/home");
  }

  function onCardClick(event, cardId) {
    if (!isDetailView) return;
    event.stopPropagation();
    navigate(`/home/${cardId}`);
  }

  function renderModal() {
    const gameCard = GAME_CARDS.find((card) => card.id === loaderData.cardId);
    if (!gameCard) return null;
    return (
      <Overlay close={closeModal}>
        <Modal card={gameCard} onCrossClick={closeModal} />
      </Overlay>
    );
  }

  const leftGameCard = GAME_CARDS[0];
  const middleGameCard = GAME_CARDS[1];
  const rightGameCard = GAME_CARDS[2];

  // If mobile rotates the screen, center gamecards,
  // since the controls might disappear.
  useEffect(() => {
    const handleResize = () => {
      setMobileSelected(0);
    };
    window.addEventListener("onorientationchange", handleResize);
    return () => {
      window.removeEventListener("onorientationchange", handleResize);
    };
  }, []);

  // Open/Close DetailView with scroll/swipe
  // Swipe cards left/right
  const SWIPE_THRESHOLD_X = 30;
  const SWIPE_THRESHOLD_Y = 50;
  const WHEEL_THRESHOLD = 50;

  const touchStart = useRef(coords);
  const touchEnd = useRef(coords);

  function handleTouchStart(event) {
    touchStart.current = {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY,
    };
    touchEnd.current = touchStart.current;
  }

  function handleTouchMove(event) {
    touchEnd.current = {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY,
    };
  }

  function handleTouchEnd() {
    const swipeDistanceX = touchEnd.current.x - touchStart.current.x;
    const isSwipingRight = swipeDistanceX > SWIPE_THRESHOLD_X;
    const isSwipingLeft = swipeDistanceX < -SWIPE_THRESHOLD_X;
    if (isDetailView && isSwipingLeft) {
      setMobileSelected((value) => clamp(value - 1, -1, 1));
    } else if (isDetailView && isSwipingRight) {
      setMobileSelected((value) => clamp(value + 1, -1, 1));
    }

    const swipeDistanceY = touchEnd.current.y - touchStart.current.y;
    const isSwipingDown = swipeDistanceY > SWIPE_THRESHOLD_Y;
    const isSwipingUp = swipeDistanceY < -SWIPE_THRESHOLD_Y;
    if (isSwipingDown) {
      setIsDetailView(false);
    } else if (isSwipingUp) {
      setIsDetailView(true);
    }
  }

  function handleOnWheel(wheelEvent) {
    const scrollNumber = wheelEvent.deltaY;
    const isScrollingDown = scrollNumber > WHEEL_THRESHOLD;
    const isScrollingUp = scrollNumber < -WHEEL_THRESHOLD;
    if (isScrollingDown) {
      setIsDetailView(true);
    } else if (isScrollingUp) {
      setIsDetailView(false);
    }
  }

  return (
    <>
      <div className="Home-Wrapper">
        <Header />
        <div
          className={stateClassName}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onWheel={handleOnWheel}
        >
          <div className="Home-LatestGames">
            <div className="Home-LatestGamesHeading-Wrapper">
              <h1 className="Home-LatestGamesHeading">Latest Games</h1>
            </div>
            <div
              className="Home-LatestGamesCards"
              onClick={() => setIsDetailView(true)}
              style={{
                transform: isDetailView
                  ? `translate(${mobileSelected * 370}px, 0)`
                  : "",
              }}
            >
              <GameCard
                name={leftGameCard.name}
                engine={leftGameCard.engine}
                language={leftGameCard.language}
                focus={leftGameCard.focus}
                focusColor={leftGameCard.focusColor}
                image={leftGameCard.cardImage}
                text={leftGameCard.cardText}
                className="Home-LeftCard"
                rotation={isDetailView ? 0 : "-23.43deg"}
                xOffset={isDetailView ? "0%" : "58%"}
                yOffset={isDetailView ? "0%" : "-2%"}
                onClick={(event) => onCardClick(event, leftGameCard.id)}
              />
              <GameCard
                name={middleGameCard.name}
                engine={middleGameCard.engine}
                language={middleGameCard.language}
                focus={middleGameCard.focus}
                focusColor={middleGameCard.focusColor}
                image={middleGameCard.cardImage}
                text={middleGameCard.cardText}
                className="Home-MiddleCard"
                rotation={isDetailView ? 0 : "-4.96deg"}
                xOffset={isDetailView ? "0%" : "0%"}
                yOffset={isDetailView ? "0%" : "-6%"}
                onClick={(event) => onCardClick(event, middleGameCard.id)}
              />
              <GameCard
                name={rightGameCard.name}
                engine={rightGameCard.engine}
                language={rightGameCard.language}
                focus={rightGameCard.focus}
                focusColor={rightGameCard.focusColor}
                image={rightGameCard.cardImage}
                text={rightGameCard.cardText}
                className="Home-RightCard"
                rotation={isDetailView ? 0 : "12.58deg"}
                xOffset={isDetailView ? "0%" : "-60%"}
                yOffset={isDetailView ? "0%" : "-1%"}
                onClick={(event) => onCardClick(event, rightGameCard.id)}
              />
            </div>
            <div className="Doodles">
              <div className="Lines">
                <Lines></Lines>
              </div>
              <div className="Click">
                <Click></Click>
              </div>
              <div className="Arrow">
                <Arrow></Arrow>
              </div>
            </div>
            <div className="Home-Controls-Wrapper">
              <div
                className="Home-Mobile-Left"
                onClick={() =>
                  setMobileSelected((value) => clamp(value + 1, -1, 1))
                }
              >
                <LeftRightArrow></LeftRightArrow>
              </div>
              <div
                className="BackCross-Wrapper"
                onClick={() => setIsDetailView(false)}
              >
                <BackCross></BackCross>
              </div>
              <div
                className="Home-Mobile-Right"
                onClick={() =>
                  setMobileSelected((value) => clamp(value - 1, -1, 1))
                }
              >
                <LeftRightArrow></LeftRightArrow>
              </div>
            </div>
          </div>
          <div className="Text-Wrapper">
            <div className="Text-Inner-Wrapper">
              <h1 className="Name-Header">Marcel Bittrich</h1>
              <h3 className="Name-Header">App | Web | Game Developer</h3>
            </div>
          </div>
        </div>
        {renderModal()}
      </div>
    </>
  );
}

function clamp(value, min, max) {
  return Math.max(min, Math.min(value, max));
}
