import "./Modal.css";
import { ReactComponent as Cross } from "../img/cross.svg";

export function ModalContentSection(props) {
  const styleForReversed = { flexDirection: "row-reverse" };
  const style = props.reversed ? styleForReversed : undefined;
  return (
    <div className="Modal-Content-Section" style={style}>
      <div className="Modal-Content-Section-Media-Wrapper">{props.media}</div>
      <div className="Modal-Content-Section-Text-Wrapper">
        <h3>{props.title}</h3>
        {props.children}
      </div>
    </div>
  );
}

export function ModalContentImplementations(props) {
  return (
    <div className="Modal-Content-Implementations">
      <h2>{props.title}</h2>
      {props.children}
    </div>
  );
}

export function Modal(props) {
  const { card } = props;
  const gameLinkClass = card.gameLinkActive
    ? "Modal-Content-Link Active"
    : "Modal-Content-Link Inactive";
  const codeLinkClass = card.codeLinkActive
    ? "Modal-Content-Link Active"
    : "Modal-Content-Link Inactive";

  return (
    <div className="Modal" onClick={(event) => event.stopPropagation()}>
      <div className="Modal-EntryImage-Wrapper">{card.entryMedia}</div>
      <div className="Modal-Content-Wrapper">
        <div className="Modal-Content-Header">
          <div className="Modal-Content-Headline">
            <h1>{props.card.name}</h1>
            <div className="Modal-Tech-Item-Container">
              <div className="Modal-Tech-Item EngineTag">{card.engine}</div>
              <div className="Modal-Tech-Item LanguageTag">{card.language}</div>
              <div
                className="Modal-Tech-Item FocusTag"
                style={{ backgroundColor: card.focusColor }}
              >
                {card.focus}
              </div>
            </div>
          </div>
          <div className="Modal-Content-Header-Spacer"></div>
          <div className="Modal-Content-Links-Wrapper">
            <a className={gameLinkClass} href={card.gameLink} target="_blank">
              <h4> Game </h4>
            </a>
            <a className={codeLinkClass} href={card.codeLink} target="_blank">
              <h4> Code </h4>
            </a>
          </div>
        </div>
        {card.modalContent}
      </div>
      <div className="Modal-Cross-Background"></div>
      <div className="Modal-Cross-Wrapper" onClick={props.onCrossClick}>
        <Cross />
      </div>
    </div>
  );
}
