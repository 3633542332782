import ReactDOM from "react-dom";
import "./Overlay.css";
import React from "react";

const OverlayRoot = document.getElementById("overlay");

export class Overlay extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.onCloseOverlay = this.onCloseOverlay.bind(this);
  }
  componentDidMount() {
    OverlayRoot.appendChild(this.el);
  }
  componentWillUnmount() {
    OverlayRoot.removeChild(this.el);
  }
  onCloseOverlay(event) {
    event.stopPropagation();
    this.props.close();
  }
  renderOverlay() {
    return (
      <div className="overlay" onClick={this.onCloseOverlay}>
        {this.props.children}
      </div>
    );
  }
  render() {
    return ReactDOM.createPortal(this.renderOverlay(), this.el);
  }
}
