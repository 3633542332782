import "./Header.css";
import { ReactComponent as GitHubLogo } from "../img/github.svg";
import { ReactComponent as LinkedInLogo } from "../img/linkedin.svg";
import { ReactComponent as MainLogo } from "../img/LogoVector.svg";
import { Link, NavLink } from "react-router-dom";

import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function Header(props) {
  // Header burger menu state state
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(null);

  const { width } = useWindowDimensions();

  const links = [
    {
      name: "Home",
      to: "/home",
    },
    {
      name: "Portfolio",
      to: "/portfolio",
    },
    {
      name: "About",
      to: "/about",
    },
  ];

  function renderMainLogo() {
    let logoClassName = "MainLogo";
    if (isBurgerMenuOpen) logoClassName += " MainLogo--White";
    return (
      <a className="MainLogo-Wrapper" href="https://www.marcelbittrich.com">
        <MainLogo className={logoClassName} alt="MainLogo" />
      </a>
    );
  }

  function renderLinks() {
    return (
      <>
        {links.map((link, i) => (
          <NavLink className="" to={link.to} key={i}>
            {/*only use this "key" approach for static arrays*/}
            {link.name}
          </NavLink>
        ))}
        <a
          className="icon"
          href="https://github.com/marcelbittrich"
          target="_blank"
        >
          <GitHubLogo className="GitHubLogo" alt="GitHub Logo" />
        </a>
        <a
          className="icon"
          href="https://linkedin.com/in/marcel-bittrich"
          target="_blank"
        >
          <LinkedInLogo className="LinkedInLogo" alt="LinkedIn Logo" />
        </a>
      </>
    );
  }

  function renderBurgerMenuIcon() {
    const addClassName = isBurgerMenuOpen ? " WhiteStripes" : "";

    return (
      <>
        <div
          className="Header-Burger-Box"
          onClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
        >
          <div className={"Bun-Top" + addClassName}></div>
          <div className="Patty"></div>
          <div className={"Bun-Bottom" + addClassName}></div>
        </div>
      </>
    );
  }

  function renderBurgerMenu() {
    return (
      <div className="Header-Burger-Menu">
        {links.map((link, idx) => (
          <Link className="Links" to={link.to} key={idx}>
            {link.name}
          </Link>
        ))}
        <a
          className="Icon"
          href="https://github.com/marcelbittrich"
          target="_blank"
        >
          <GitHubLogo className="GitHubLogo" alt="GitHub Logo" />
        </a>
        <a
          className="Icon"
          href="https://linkedin.com/in/marcel-bittrich"
          target="_blank"
        >
          <LinkedInLogo className="LinkedInLogo" alt="LinkedIn Logo" />
        </a>
      </div>
    );
  }

  let className = "header"
  if (isBurgerMenuOpen) className += " header--open"
  return (
    <>
      <header className={className}>
        <div>{renderMainLogo()}</div>
        <div className="Nav-Bar">
          {width > 600 ? renderLinks() : renderBurgerMenuIcon()}
        </div>
      </header>
      {isBurgerMenuOpen && width < 600 ? renderBurgerMenu() : null}
    </>
  );
}
